<template>
  <div class="app-main-content">
    <div class="header">
      <div class="title">
        <h2>全部项目计划与进度<img class="icon" :src="$toUrl('menu/plan.png')"></h2>
        <div>共{{ workitemsSta?.totalcount }}条，其中存在警告提醒的有{{ workitemsSta?.warning_count }}条</div>
      </div>
      <div class="btns">
        <a-checkbox style="margin-left: 15px" :checked="checked" @change="toggleGantt">
          显隐
        </a-checkbox>
        <a-select style="margin-left: 15px; width: 50px" v-model="ganttDate" size="small" @change="changeGanttDate">
          <a-select-option value="day">
            天
          </a-select-option>
          <a-select-option value="week">
            周
          </a-select-option>
          <a-select-option value="month">
            月
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div ref="content" class="content d-flex">
      <div v-if="phaseList.length > 0" class="left" style="width: 70%;">
        <a-table style="min-width: 1280px;" v-if="phaseList.length > 0" class="edp-table-warning"
                 :data-source="phaseList" rowKey="oid"
                 :expandIcon="customExpandIcon" :indentSize="25"
                 :defaultExpandAllRows="true" :columns="columns" :pagination="false" :rowClassName="setRowClassName">
          <template slot="phase_name" slot-scope="scope, row">
            <span class="edp-title">
  <!--            <img :src="`${$appsettings.imgPrefix}${row.issue_type_name === '里程碑' ? 'milepost' :-->
              <!--             row.issue_type_name === '需求' ? 'xuqiu' : row.issue_type_name === '任务' ? 'renwu' : 'phase'}.png`" alt="">-->
              <template v-if="row.issue_type_name === '里程碑'">
                <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('milepost_red.png')" alt="">
                <img v-else-if="row.issue_status === '0'" :src="$toUrl('milepost_blue.png')" alt="">
                <img v-else-if="row.issue_status === '1'" :src="$toUrl('milepost_yellow.png')" alt="">
                <img v-else-if="row.issue_status === '100'" :src="$toUrl('milepost_green.png')" alt="">
              </template>
              <template v-else-if="row.issue_type_name === '任务'">
                <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')" alt="">
                <img v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                <img v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                <img v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
              </template>
              <img v-else :src="$toUrl('phase.png')" alt="">

              <a-tooltip>
                <template slot="title">{{ row.issue_name ? row.issue_name : scope }}</template>
                <span class="name">{{ row.issue_name ? row.issue_name : scope }}</span>
              </a-tooltip>
            </span>
          </template>
          <template slot="issue_type_name" slot-scope="scope">
            <div style="height: 24px;">{{ scope ? scope : '阶段' }}</div>
          </template>
          <template slot="complete_percent" slot-scope="scope">
            <span>{{ scope ? `${scope}%` : `` }}</span>
          </template>
          <template slot="issue_level_name" slot-scope="_,row">
            <edp-select
              disabled
              v-if="row.issue_type === 'T'"
              style="width: 45px"
              :skin="`status ${row.issue_level === '100' ? 'green' : row.issue_level === '200' ?
              'yellow' : row.issue_level === '300' ? 'blue' : row.issue_level === '400' ? 'red' : ''}`"
              v-model="row.issue_level"
              :db="dbs.prjIssue"
              field-name="issue_level"
              :oid="row.oid"
              :prj-oid="row.prjoid"
              :options="issues_level_list"
              options-type="cat"
              @change="getPhaseList"
            >
            </edp-select>
          </template>
          <template slot="schedule_start_date" slot-scope="_, row">
            <span v-if="row.issue_type === 'T' || row.issue_type_name === '阶段'">{{row.schedule_start_date}}</span>
          </template>
          <template slot="schedule_end_date" slot-scope="_, row">
            {{row.schedule_end_date}}
          </template>
          <template slot="actual_end_date" slot-scope="_, row">
            <span v-if="row.issue_type === 'T' || row.issue_type === 'M'">{{row.actual_end_date}}</span>
          </template>
        </a-table>
      </div>
      <edp-empty v-else style="margin-top: 30px;"></edp-empty>
      <dhtmlx-gantt ref="dhtmxGantt" v-if="!checked" :tasks="ganntList"></dhtmlx-gantt>
    </div>
  </div>
</template>

<script>
import { fetch } from "@/utils/request";
import { h } from "vue";
import dhtmlxGantt from "@/components/dhtmlx-gantt.vue";
import { queryChildren } from "@/utils/helpFunc";
import gantt from "dhtmlx-gantt";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import EdpSelect from "@/components/e-form/edp-select.vue";
import catMixin from "@/utils/mixins/catMixin";

const columns = [
  {
    title: '标题',
    dataIndex: 'phase_name',
    scopedSlots: { customRender: 'phase_name' },
  },
  {
    title: '类型',
    dataIndex: 'issue_type_name',
    scopedSlots: { customRender: 'issue_type_name' },
    width: "117px"
  },
  {
    title: '进度',
    dataIndex: 'complete_percent',
    width: '85px',
    scopedSlots: { customRender: 'complete_percent' },
  },
  {
    title: '优先级',
    dataIndex: 'issue_level_name',
    width: '85px',
    scopedSlots: { customRender: 'issue_level_name' },
  },
  {
    title: '计划开始',
    dataIndex: 'schedule_start_date',
    width: '130px',
    scopedSlots: { customRender: 'schedule_start_date' }
  },
  {
    title: '计划结束',
    dataIndex: 'schedule_end_date',
    width: '130px',
    scopedSlots: { customRender: 'schedule_end_date' }
  },
  {
    title: '实际结束',
    dataIndex: 'actual_end_date',
    width: '130px',
    scopedSlots: { customRender: 'actual_end_date' }
  }
];
export default {
  name: "projectPlanList",
  mixins: [catMixin],
  data() {
    return {
      dbs: {
        prjPhase: new FieldUpdateDb("prj", "prj_phase"),
        prjIssue: new FieldUpdateDb("prj", "prj_issue"),
      },
      columns,
      phaseList: [],
      tableHeight: 0,
      workitemsSta: {},
      prj_oid: '',
      phaseData: {},
      ganntList: {
        data: [
          // {id: 1, name: 'Task #1', start_date: '2023-01-17', end_date: '2023-07-12',value: '2', progress: 0.1},
          // {id: 10, name: 'Task #1', start_date: '2023-01-17', end_date: '2023-07-12',value: '2', progress: 0.1, parent: 1},
          // {id: 4, name: '测试任务1', start_date: '2023-03-10', end_date: '2023-06-25',value: '1', progress: 1, parent: 1},
          // {id: 2, name: 'Task #2', start_date: '2023-01-20', end_date: '2023-07-12',value: '1', progress: 0.4}
        ],
        links: []
      },
      checked: false,
      ganttDate: 'day',
      cat: {
        issues_level: [],
      },
      principalList: []
    }
  },
  components: {
    dhtmlxGantt,
    EdpSelect
  },
  computed: {
    issues_level_list() {
      return this.cat.issues_level.slice().reverse();
    }
  },
  created() {
    this.prj_oid = this.$route.query.prj_oid
    this.getPrincipal()
  },
  mounted() {
    if (this.prj_oid) {
      this.getPhaseList()
      this.getGanttList(() => {
        this.$refs.dhtmxGantt.gantt.clearAll()
      })
      this.getWorkitemSta(this.prj_oid)
    }
  },
  methods: {
    // 获取负责人
    async getPrincipal() {
      const res = await fetch('post', '/prj/userlist/list', { prjoid: this.prj_oid })
      if (res.status === 0) {
        this.principalList = res.data.map(item => {
          return {
            label: item.name,
            value: item.user_oid,
            key: item.user_oid
          }
        })
      }
    },
    hasDeleteP(row) {
      let oid = JSON.parse(localStorage.getItem("company_info")).account_oid
      if (row.issue_type_name === '任务') {
        if (row.issue_owner === oid || row.creator_oid === oid) {
          return true
        }
        return this.$hasP("T_DELALL")
      } else if (row.issue_type_name === '阶段') {
        if (row.phase_owner?.split('/').includes(oid) || row.creator_oid === oid) {
          return true
        }
        return this.$hasP("H_DEL")
      } else {
        return true
      }
    },
    getPhaseName(phase_oid) {
      if (phase_oid in this.phaseData) {
        return this.phaseData[phase_oid]
      }
      return ""
    },
    openTaskDetailModal(row) {
      this.$refs.taskDetailModal.open(row.oid)
    },
    // 获取项目阶段列表
    getPhaseList() {
      fetch('post', '/prj/phase/issue/retrieve', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          this.phaseData = []
          this.phaseList = data.map(e => {
            this.phaseData[e.oid] = e.phase_name
            return { ...e, editName: false, issue_type_name: "阶段" }
          })
          console.log(this.phaseList);
          console.log(this.prj_oid);
          queryChildren(this.phaseList)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取甘特图列表
    getGanttList(cb) {
      fetch('post', '/prj/phase/issue/retrieve/chart', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          this.ganntList.data = data.map(e => {
            return {
              ...e,
              start_date: e.schedule_start_date || '',
              end_date: e.schedule_end_date || '',
              progress: e.complete_percent / 100,
              type: e.issue_type === 'M' ? 'milestone' : '',
              bar_height: e.issue_type === 'M' ? 28 : 8,
              duration: e.issue_type === 'M' ? 1 : 0
            }
          })
          if (cb) cb()
          // this.$refs.dhtmxGantt.gantt.clearAll() //清空数据
          gantt.addMarker({ //显示当天刻度线
            start_date: new Date(),
            css: "marker-today",//today样式class为自带，其他的时间标识线需要自己定义class
            text: "今天",
            title: "今天: "
          })
          this.$refs.dhtmxGantt.gantt.parse(this.ganntList)
          // this.$refs.dhtmxGantt.gantt.refreshData()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取阶段下的子任务
    getPhaseIssueList(oid, cb) {
      fetch('post', '/prj/issue/retrieve/toplevel/byphase', {
        phase_oid: oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          data = data.map(e => {
            return { ...e, editName: false }
          })
          cb(data)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取子任务的子级
    getIssueChildrenList(oid, cb) {
      fetch('post', '/prj/issue/retrieve/children', {
        oid: oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          cb(data)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getWorkitemSta(prjoid) {
      fetch('post', '/prj/issue/workitems/sta', {
        prjoid
      }).then((res) => {
        if (res.status === 0) {
          this.workitemsSta = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openModal(row) {
      this.$refs.modal.open({
        phase_oid: row.oid,
        phase_name: row.phase_name
      })
    },
    // 打开导出对话框
    openExportModal(row) {
      this.$refs.exportModal.open({
        phase_oid: row.oid,
        phase_name: row.phase_name
      })
    },
    openTaskModal(row) {
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        phase_name: this.getPhaseName(row.oid),
        phase_oid: row.oid
      })
    },
    openChildrenTaskModal(row) {
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        route_level: row.route_level - 0 + 1,
        route_map: row.route_map + '/' + row.oid,
        phase_name: this.getPhaseName(row.phase_oid),
        phase_oid: row.phase_oid
      })
    },
    openRelationWorkModal(row) {
      this.$refs.relationWorkModal.open(row)
    },
    del(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          console.log(row?.issue_type_name);
          let url = '/prj/phase/delete'
          if (row?.issue_type_name === '需求') {
            url = '/prj/requirement/delete'
          } else if (row?.issue_type_name === '里程碑' || row?.issue_type_name === '任务') {
            url = '/prj/issue/delete'
          }
          fetch('post', url, {
            oid: row.oid
          }).then((res) => {
            if (res.status === 0) {
              this.queryTree(this.phaseList, row.oid)
              this.$message.success('删除成功！')
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    queryTree(datas, oid) { // 递归循环查树结构元素
      datas.forEach((item, index) => {
        if (item.oid === oid) {
          // console.log(datas, datas[index]);
          this.$delete(datas, index)
          return;
        }
        if (item.children) {  //存在子节点就递归
          this.queryTree(item.children, oid);
        }
      })
    },
    // 自定义展开图标
    customExpandIcon(props) {
      // 是否有子项，无子项不展示图标
      // record是每行的数据，后端返回的数据会有一个hasChild字段判断是否有子项
      if (props.record && props.record.end_flag === '1') {
        // props.expanded a-table组件判断是否展开了为true是展开,false为没展开
        if (props.expanded) {
          // onClick事件必须添加上，相当于把a-table里的展开事件给了自定义的图标，不加的话点击会失效
          return h("i", {
            class: "iconfont icon-xiangxia",
            on: {
              click: e => {
                props.onExpand(props.record, e)
                this.$refs.dhtmxGantt.gantt.close(props.record.oid)
              }
            }
          })
        } else {
          return h("i", {
            class: "iconfont icon-xiangxia", style: 'transform: rotate(-90deg)',
            on: {
              click: e => {
                props.onExpand(props.record, e)
                this.$refs.dhtmxGantt.gantt.open(props.record.oid)
                // console.log(props);
                // 点击获取子任务
                // if (!props.record?.children?.length > 0) {
                //   if (!props.record.issue_type_name) {
                //     this.getPhaseIssueList(props.record.oid, (data) => {
                //       this.$set(props.record, 'children', data)
                //     })
                //   } else {
                //     this.getIssueChildrenList(props.record.oid, (data) => {
                //       this.$set(props.record, 'children', data)
                //     })
                //   }
                // }
              }
            }
          })
        }
      } else {
        return h("span", { style: 'padding-left: 27px' })
      }
    },
    // 设置每列延期样式
    setRowClassName(record) {
      // console.log(record);
      return record.is_later === '1' ? 'edp-warning' : ''
    },
    clickFoucs(row) {
      // console.log(row);
      this.$set(row, 'editName', true)
      this.$nextTick(() => {
        this.$refs["table-name"].$refs.input.focus()
      })
    },
    fieldUpdate(row) {
      // console.log(row);
      this.$set(row, 'editName', false)
      if (row.phase_name) {
        this.$updateOnefield('prj', 'prj_phase', 'phase_name', row.phase_name, row.oid, row.prjoid)
      } else {
        this.$updateOnefield('prj', 'prj_issue', 'issue_name', row.issue_name, row.oid, row.prjoid)
      }
    },
    reload() {
      this.getPhaseList()
      this.getGanttList()
    },
    toggleGantt() {
      this.checked = !this.checked;
    },
    changeGanttDate() {
      // console.log(this.ganttDate);
      // console.log(this.$refs.dhtmxGantt);
      if (this.ganttDate === 'week') {
        let weekScaleTemplate = function (date) {
          let dateToStr = gantt.date.date_to_str("%d");
          let endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
          return `${dateToStr(date)} - ${dateToStr(endDate)}`;
        }
        this.$refs.dhtmxGantt.gantt.config.scales = [
          {
            unit: "month", step: 1, format: function (date) {
              return `${date.getFullYear()}年${date.getMonth() + 1}月`
            }
          },
          { unit: "week", step: 1, format: weekScaleTemplate }
        ]
      } else if (this.ganttDate === 'day') {
        this.$refs.dhtmxGantt.gantt.config.scales = [
          {
            unit: "month", step: 1, format: function (date) {
              return `${date.getFullYear()}年${date.getMonth() + 1}月`
            }
          },
          { unit: "day", step: 1, format: "%j" }
        ]
      } else if (this.ganttDate === 'month') {
        this.$refs.dhtmxGantt.gantt.config.scales = [
          {
            unit: "year", step: 1, format: function (date) {
              return `${date.getFullYear()}年`
            }
          },
          {
            unit: "month", step: 1, format: function (date) {
              return `${date.getMonth() + 1}月`
            }
          }
        ]
      }

      this.$refs.dhtmxGantt.gantt.init(this.$refs.dhtmxGantt.$el)
    }
  }
}
</script>

<style scoped lang="less">
.mini-number-input {

}
.app-main-content {
  padding: 0 7px;
  box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 46px 12px 20px;
    background: #FFFFFF;
    border-radius: 4px 4px 0 0;
    font-weight: 500;
    color: #494949;

    .title {
      font-size: 12px;

      h2 {
        margin: 0 0 5px 0;
        font-size: 18px;
        font-weight: 500;
        color: #494949;
        display: flex;
        align-items: center;
        .icon {
          margin-left: 10px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .btns {
    button {
      font-weight: 500;
      font-size: 14px;
      border-radius: 3px;
      //height: 30px;
      //line-height: 30px;
      //color: #FFFFFF;
      //background-color: var(--primary-color);
      //border-color: var(--primary-color);
    }
  }

  .content {
    height: calc(100% - 87px);
    background: #FFFFFF;
    overflow-y: auto;
    display: flex;

    > .left {
      width: 70%;
      overflow-y: auto;
    }
  }
}
</style>
